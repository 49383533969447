import { z } from 'zod'

import { colorSchema } from './color.ts'

export const responseCodeCollectionSchema = z
  .object({
    generated_response_codes_error: z.record(z.string()),
    generated_response_codes_ok: z.record(z.string()),
  })
  .strict()
export type ResponseCodeCollection = z.infer<typeof responseCodeCollectionSchema>

export const termSchema = z
  .object({
    description: z.string(),
    aliases: z.array(z.string()),
  })
  .strict()
export type Term = z.infer<typeof termSchema>

export const terminologySchema = z.record(termSchema)
export type Terminology = z.infer<typeof terminologySchema>

export const fsPlanSchema = z.object({
  slug: z.string(),
  name: z.string(),
  color: z.string(),
  virus_scanning: z.boolean(),
  ai_features: z.boolean(),
  distributed_video_transcoding: z.boolean(),
  file_size_limit_in_gb: z.number(),
  overage: z.literal(false).or(z.string()),
  price_period: z.string(),
  gb_period: z.string(),
  price: z.string(),
  priority_job_slots: z.number(),
  seats: z.number(),
  support: z.enum(['bronze', 'silver', 'gold', 'diamond']),
  included_gb: z.number(),
  highlighted: z.boolean().optional(),
})

export type FsPlan = z.infer<typeof fsPlanSchema>
export type FsPlans = Record<string, FsPlan>

export const fsPlanCollectionSchema = z.record(fsPlanSchema)
export type FsPlanCollection = z.infer<typeof fsPlanCollectionSchema>

export const boastSchema = z.record(z.string().or(z.number()).or(z.array(z.string())))
export type BoastFacts = z.infer<typeof boastSchema>

export const testimonialSlugSchema = z.enum(['thoughtbot', 'shuttlerock', 'coursera'])

export const benefitSlugSchema = z.enum([
  'cost-effective',
  'developer-support',
  'free-resources',
  'full-ownership',
  'future-proof',
  'industry-pioneers',
  'integrations',
  'privacy-security',
  'scalability',
  'time-to-market',
  'unified-api',
  'vendor-lock-in',
  'workflows',
])
export const benefitSchema = z.object({
  title: z.string().min(3).max(60),
  slug: benefitSlugSchema,
  color: colorSchema.describe('Cycle through the colors').optional(),
  fa_icon: z
    .string()
    .describe('FontAwesome icon that most closely matches the benefit. For example: fa-staff-snake')
    .optional(),
  description: z.string().min(30).max(200),
  link: z
    .string()
    .nullable()
    .describe(
      "Internal Transloadit link, so it starts with /. Don't make these up. Either copy them literally from what is provided in the prompt, or leave null",
    )
    .optional(),
})
export type Benefit = z.infer<typeof benefitSchema>
export const benefitsSchema = z.array(benefitSchema)
export type Benefits = z.infer<typeof benefitsSchema>

export const featureSlugSchema = z.enum([
  'video-encoding',
  'adaptive-streaming',
  'video-concatenation',
  'video-subtitles',
  'video-thumbnails',
  'audio-encoding',
  'audio-concatenation',
  'audio-waveform',
  'audio-looping',
  'audio-artwork',
  'image-resizing',
  'image-optimization',
  'spritesheet-generation',
  'face-detection',
  'object-recognition',
  'text-recognition',
  'speech-transcription',
  'text-to-speech',
  'text-translation',
  'document-conversion',
  'document-merging',
  'document-thumbnails',
  'file-compression',
  'file-decompression',
  'file-virus-scanning',
  'file-type-verification',
  'file-hashing',
  'metadata-writing',
  'file-preview',
  'global-file-delivery',
  'secure-file-handling',
  'file-upload-handling',
  'file-importing',
  'file-exporting',
  'custom-workflows',
  'ai-recognition',
  'media-cataloging',
  'adaptive-file-delivery',
  'rate-limiting',
  'signature-authentication',
  'sandboxed-encoding',
  'region-specific-processing',
  'high-uptime',
  'scalable-infrastructure',
  'developer-api',
  'custom-encoding-presets',
  'watermarking',
  'script-execution',
  'speech-synthesis',
  'speech-translation',
  'playlist-generation',
  'secure-api-access',
  'custom-metadata',
  'thumbnail-generation',
  'image-description',
  'file-filtering',
  'customizable-workflows',
  'multi-cloud-support',
  'gdpr-compliance',
  'hipaa-compliance',
  'ccpa-compliance',
  'encrypted-storage',
  'two-factor-authentication',
  'pci-dss-compliance',
  'custom-api-endpoints',
  'automated-file-deletion',
  'custom-encoding-pipelines',
  'media-analysis',
  'custom-rate-limits',
  'real-time-monitoring',
  'disaster-recovery',
  'workflow-version-control',
  'ci-support',
  'custom-file-delivery',
  'video-analysis',
  'audio-analysis',
  'custom-security-policies',
  'multi-region-support',
  'metadata-extraction',
  'speech-analysis',
  'custom-file-processing',
  'document-analysis',
  'custom-file-exporting',
  'image-analysis',
  'custom-file-importing',
  'metadata-analysis',
  'custom-file-handling',
  'workflow-optimization',
  'custom-file-storage',
  'file-optimization',
  'file-encryption',
  'file-delivery-optimization',
  'file-access-control',
  'file-security',
])

export const featureSchema = z.object({
  title: z.string().min(3).max(60),
  slug: featureSlugSchema,
  description: z.string().min(30).max(200),
  fa_icon: z
    .string()
    .describe('FontAwesome icon that most closely matches the feature. For example: fa-staff-snake')
    .min(3)
    .max(30)
    .optional(),
  color: colorSchema.describe('Cycle through the colors').optional(),
  link: z
    .string()
    .nullable()
    .describe(
      "Internal Transloadit link, so it starts with /. Don't make these up. Either copy them literally from what is provided in the prompt, or leave null",
    )
    .optional(),
})
export type Feature = z.infer<typeof featureSchema>
export const featuresSchema = z.array(featureSchema)
export type Features = z.infer<typeof featuresSchema>

export const companySchema = z.object({
  name: z.string().min(2).max(50),
  logo: z.string().startsWith('/assets/images/'),
  url: z.string().url(),
})

export const authorSchema = z.object({
  name: z.string().min(2).max(50),
  role: z.string().min(2).max(50),
  avatar: z.string().startsWith('/assets/images/'),
  company: companySchema,
})

export const testimonialSchema = z.object({
  quote: z.string().min(20).max(200),
  slug: testimonialSlugSchema,
  author: authorSchema,
})

export const testimonialsSchema = z.array(testimonialSchema)
export type Testimonials = z.infer<typeof testimonialsSchema>
